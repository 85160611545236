import gql      from 'graphql-tag';


export const ARTIST_SIGN_IN = gql`
mutation ($input: LoginInput!) {
  loginArtist(input: $input) {
    ... on Artist {
      __typename
      Firstname
      Lastname
      ID
      Token
      Description
      ActivityInformations{
        __typename
      }
      ArtisteNickname
      ArtisteNicknameGenerated
    }

    ... on WrongPassword {
      __typename
      message
      status
    }

    ... on UserNotFound {
      __typename
      message
      status
    }

    ... on WrongInput {
      __typename
      errors {
        message
        status
      }
    }

    ... on EmailNotValidate {
      __typename
      message
      status
    }
  }
}
`;
