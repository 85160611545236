<template>
  <router-link
    v-if="urlPath || $attrs.href"
    :to="urlPath"
    class="text-link__wrapper">
    <component
      :is="tag"
      :class="[linkStyle, 'text-link__link']">
      <slot></slot>
    </component>
  </router-link>

  <div
    v-else
    class="text-link__wrapper">
    <component
      v-on="$listeners"
      v-bind="$attrs"
      :is="tag"
      :class="[linkStyle, 'text-link__link']">
      <slot></slot>
    </component>
  </div>
</template>

<script>

export default {
  name: 'a-TextLink',
  props: {
    color: {
      type: String,
      default: 'black',
      validator: (color) => ['black', 'white'].includes(color),
    },
    tag: {
      type: String,
      default: 'span',
      validator: (tag) => ['a', 'span', 'button'].includes(tag),
    },
    urlPath: {
      type: String,
      default: '',
    },
  },
  computed: {
    linkStyle() {
      return {
        [`text-link__link--${this.color}`]: true,
      };
    },
  },
};

</script>

<style lang="scss">

.text-link {
  &__wrapper {
    display: inline-flex;
  }

  &__link {
    position: relative;
    display: flex;
    align-items: center;
    font-size: var(--text-xs);
    font-family: var(--font-stack-secondary);
    font-weight: var(--font-bold);
    text-decoration: underline;
    text-underline-offset: var(--space-xxs);

    $colors: (
      "black": var(--color-black),
      "white": var(--color-white),
    );

    @each $color-key, $color-value in $colors {
      &--#{$color-key} {
        color: $color-value;
        // @if $color-key == "white" {
        //   &:after {
        //     background: var(--color-white) !important;
        //   }
        // }
      }
    }
  }
}

</style>
