<template>
  <MainLayout
    isFullScreen
    class="main-layout--signin-artist">
    <img
      v-if="$mediaQueries.isDesktop"
      src="../../../../public/assets/images/artiste/signin.jpg"
      alt=""
      class="signin__hero">
    <router-link
      :to="{ name: 'LandingPage' }"
      class="signin__logo">
      <Logo
        :size="$mediaQueries.isDesktop ? 'base' : 'sm'"
        :color="$mediaQueries.isDesktop ? 'white' : 'black'">
      </Logo>
    </router-link>
    <section class="signin__wrapper">
      <div class="signin__header-links">
        <router-link
          :to="{ name: 'ArtistSignin' }"
          class="signin__header-link"
          active-class="signin__header-link--active">
          {{ $t('artist.signin.iAmArtist') }}
        </router-link>
        <router-link
          :to="{ name: 'ClientSignin' }"
          class="signin__header-link"
          active-class="signin__header-link--active">
          {{ $t('artist.signin.iAmBooker') }}
        </router-link>
      </div>
      <div class="signin__header-links">
        <router-link
          :to="{ name: 'LandingPage' }"
          class="signin__header-back mr-auto">
          <Icon
            :variant="'arrow-left-outline'"
            :size="'xxs'"
            class="mr-xs">
          </Icon>
          {{ $t('common.back') }}
        </router-link>
        <template v-if="$mediaQueries.isDesktop">
          <div class="signin__header-back mr-base">
            {{ $t('artist.signin.noAccount') }}
          </div>
          <router-link :to="{ name: 'ArtistSignup' }">
            <Button
              isWhite
              type="button">
              {{ $t('artist.signup.createAccount') }}
            </Button>
          </router-link>
        </template>
      </div>
      <header class="signin__header">
        <Heading class="mb-base">
          {{ $t('artist.signin.connect') }}
        </Heading>
        <Paragraph
          :size="'base'"
          class="mr-xxs">
          {{ $t("artist.signup.description") }}
        </Paragraph>
      </header>

      <form
        @submit.prevent="onSubmit"
        class="signin__form"
        ref="form"
        novalidate>
        <FieldInput
          @input="sanitizeMail"
          v-model="signinEmail"
          :label="`${$t('common.email')}*`"
          :placeholder="'email@email.fr'"
          isAutofocus
          id="signin-mail"
          type="email"
          autocomplete="email"
          required
          class="mb-base">
        </FieldInput>
        <FieldInput
          v-model="signinPassword"
          :label="`${$t('common.password')}*`"
          :placeholder="$t('common.password')"
          hasRightIcons
          ref="password"
          id="signin-password"
          type="password"
          autocomplete="current-password"
          required
          class="mb-base">
          <template #icons-right>
            <Icon
              @click="$refs.password.$refs.input.type = $refs.password.$refs.input.type === 'password' ? 'text' : 'password'"
              :variant="'view-outline'"
              :tag="'button'"
              :size="'xs'"
              class="ml-xs"
              type="button">
            </Icon>
          </template>
        </FieldInput>
        <TextLink
          :urlPath="'/artist/recovery'"
          class="mb-lg">
          {{ $t('common.forgotPassword') }}
        </TextLink>

        <Loader v-if="isLoading" class="mt-auto"></Loader>
        <Button
          v-else
          :isFluid="!$mediaQueries.isDesktop"
          type="submit"
          :class="{ 'mt-auto': !$mediaQueries.isDesktop }">
          {{ $t("artist.signin.connect") }}
        </Button>
        <Paragraph
          :size="'sm'"
          class="mt-lg">
          *{{ $t('common.mandatoryField') }}
        </Paragraph>
      </form>

      <div
        v-if="!$mediaQueries.isDesktop"
        class="signin__create-account-mobile">
        <div class="signin__header-back my-base">
          {{ $t('artist.signin.noAccount') }}
        </div>
        <router-link :to="{ name: 'ArtistSignup' }">
          <Button
            isWhite
            type="button">
            {{ $t('artist.signup.createAccount') }}
          </Button>
        </router-link>
      </div>

      <LegalLinks class="mt-lg"></LegalLinks>
    </section>
  </MainLayout>
</template>

<script>

import {
  mapActions,
  mapGetters,
  mapMutations,
}                                       from 'vuex';

import MainLayout                       from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Icon                             from '../../../components/atoms/Icon/a-Icon.vue';
import Logo                             from '../../../components/atoms/Logo/a-Logo.vue';
import Heading                          from '../../../components/atoms/Heading/a-Heading.vue';
import TextLink                         from '../../../components/atoms/TextLink/a-TextLink.vue';
import Paragraph                        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FieldInput                       from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button                           from '../../../components/atoms/Button/a-Button.vue';
import Loader                           from '../../../components/atoms/Loader/a-Loader.vue';
import LegalLinks                       from '../../../components/molecules/LegalLinks/m-LegalLinks.vue';
import { ARTIST_SIGN_IN }               from '../api/queries/q-artist-sign-in';
import { ARTIST_SIGN_IN_RESPONSE }      from '../api/responses/r-artist-sign-in.js';


export default {
  name: 'p-ArtistSignin',
  components: {
    MainLayout,
    Icon,
    Logo,
    Heading,
    TextLink,
    Paragraph,
    FieldInput,
    Button,
    Loader,
    LegalLinks,
  },
  data: () => ({
    signinEmail: '',
    signinPassword: '',
    isLoading: false,
  }),
  computed: {
    ...mapGetters({
      hasCompletedEmboarding: 'User/HAS_COMPLETED_EMBOARDING',
      currentEmboardingStep: 'User/CURRENT_EMBOARDING_STEP',
    }),
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
      setUser: 'User/SET_USER',
      auth:'User/AUTHENTICATE',
    }),
    ...mapActions({
      fetchMe: 'User/FETCH_ME',
    }),
    sanitizeMail(value){
      this.signinEmail = value.toLowerCase().trim();
    },
    async onSubmit() {
      const isFormValid = this.$refs.form.checkValidity();

      if (!isFormValid) return;

      try {
        this.isLoading = true;

        const data = await this.$apollo.mutate({
          mutation: ARTIST_SIGN_IN,
          variables: {
            input: {
              email: this.signinEmail,
              password: this.signinPassword,
            },
          },
        });
        const { '__typename': responseTypeName, ...userData } = data.data.loginArtist;

        switch (responseTypeName) {
          case ARTIST_SIGN_IN_RESPONSE.artist:
            this.auth(userData);
            this.setUser(userData);

            await this.fetchMe();

            this.$router.push({ name: this.hasCompletedEmboarding ? 'ArtistProfile' : this.currentEmboardingStep });
            break;

          case ARTIST_SIGN_IN_RESPONSE.emailNotValidated:
            this.$router.push({ name: 'ArtistMailValidation' });
            break;

          case ARTIST_SIGN_IN_RESPONSE.wrongPassword:
          case ARTIST_SIGN_IN_RESPONSE.wrongInput:
          case ARTIST_SIGN_IN_RESPONSE.userNotFound:
            this.addErrors([{ type: 'alert', message: this.$t(`artist.signin.errors.${responseTypeName}`) }]);
            break;

          case ARTIST_SIGN_IN_RESPONSE.unknownError:
            this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
            break;
        }
      } catch (error){
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      }
      finally {
        this.isLoading = false;
      }
    }
  },
};
</script>

<style lang="scss">

.signin {
  &__hero {
    grid-row: 1 / 2;
    object-fit: cover;

    @media screen and ($desktop) {
      position: fixed;
      left: 0;
      right: 0;
      min-width: 50%;
      max-width: 50%;
      min-height: 100%;
      max-height: 100%;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--space-md);
    padding-bottom: 0;

    @media screen and ($desktop) {
      position: absolute;
      top: 40px;
      left: 10%;
      top: 100px;
      padding: 0;
    }
  }

  &__wrapper {
    display: grid;
    grid-column: 1 / 2;
    grid-template-rows: 70px 40px 175px 1fr;
    padding: var(--space-lg) var(--space-md);

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-template-columns: 1fr;
      grid-template-rows: minmax(70px, auto) minmax(70px, auto) 175px 1fr auto;
      max-width: 700px;
      padding: var(--space-xxl);
    }
  }

  &__header-links {
    display: flex;
    align-items: baseline;
  }

  &__header-link {
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    color: var(--color-grey-neutral);

    &:not(:last-of-type) {
      margin-right: var(--space-lg);

      @media screen and ($desktop) {
        margin-right: var(--space-xl);
      }
    }

    &--active {
      text-underline-offset: var(--space-xxs);
      text-decoration: underline;
      color: var(--color-black);
    }
  }

  &__header-back {
    display: flex;
    align-items: baseline;
    font-size: var(--text-sm);
    font-family: var(--font-stack-secondary);
  }

  &__header {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-self: end;

    @media screen and ($desktop) {
      align-items: flex-start;
      align-self: start;
    }
  }

  &__create-account-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: var(--space-md) 0;
  }
}
</style>
