<template>
  <ul class="legal-links__links-list">
    <li
      v-for="(legalLink, index) in legalLinks"
      :key="`legal-link-${index}`"
      class="legal-links__links-item">
      <router-link :to="{ name: legalLink.link }">
        {{ legalLink.label }}
      </router-link>
    </li>
  </ul>
</template>

<script>

export default {
  name: 'm-LegalLinks',
  computed: {
    legalLinks() {
      return [
        {
          label: this.$t('footer.privacyInfo'),
          link: 'ServiceTerms',
        },
        {
          label: this.$t('footer.termsAndConditions'),
          link: 'ServiceTerms',
        },
      ];
    },
  },
}

</script>

<style lang="scss">

.legal-links {
  &__links-list {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and ($desktop) {
      display: flex;
      align-items: center;
    }
  }

  &__links-item {
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
    font-weight: var(--font-light);

    &:not(:last-child) {
      margin-right: var(--space-md);
    }
  }
}

</style>
