import { COMMON_API_RESPONSES } from "../../../../constants/common-api-responses";

export const ARTIST_SIGN_IN_RESPONSE = {
    // Success
    artist: 'Artist',
    // Managed Failures
    userNotFound: 'UserNotFound',
    wrongPassword: 'WrongPassword',
    wrongInput: 'WrongInput',
    emailNotValidated: 'EmailNotValidate',
    ...COMMON_API_RESPONSES
};
